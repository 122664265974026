import { useEffect, useState } from "react";
import CSS from "./static/Text.module.css";

const Text = (
    {
        children,
        size,
        style,
        color,
        weight,
        class_name_list,
        onClick,
        bold,
        is_container_highlight,
        text_wrap,
        is_title,
    }:{
        children?: React.ReactNode;
        size?: "size1" | "size2" | "size3" | "size4" | "size5" | "size6" | "size7";
        style?: "italic";
        color?: "dark" | "green" | "white" | "red" | "mint" | "light_gray";
        weight?: "weight100" | "weight200" | "weight300" | "weight400" | "weight500" | "weight600" | "weight700" | "weight800" | "weight900";
        class_name_list?: string[];
        onClick?: React.MouseEventHandler<HTMLDivElement>;
        bold?: boolean;
        is_container_highlight?: boolean;
        text_wrap?: "nowrap" | "wrap" | "balance" | "pretty";
        is_title?: boolean;
    }
) => {
    const l_total_class_name_list: string[] = [];

    if(onClick){
        l_total_class_name_list.push("g_click_event_item");
    }

    if(class_name_list){
        for(const class_name of class_name_list){
            l_total_class_name_list.push(class_name);
        }
    }

    l_total_class_name_list.push(CSS.l_text);

    if(weight){
        l_total_class_name_list.push(CSS.weight);
    }

    if(size){
        l_total_class_name_list.push(CSS[size]);
    }

    if(color){
        l_total_class_name_list.push(CSS.color);
        l_total_class_name_list.push(CSS[color]);
    }

    if(bold){
        l_total_class_name_list.push(CSS.bold);
    }

    if(style){
        l_total_class_name_list.push(CSS.style);
    }

    if(is_container_highlight){
        l_total_class_name_list.push(CSS.is_container_highlight);
    }

    if(text_wrap){
        l_total_class_name_list.push(CSS.text_wrap);
        l_total_class_name_list.push(CSS[text_wrap]);
    }

    if(is_title){
        l_total_class_name_list.push(CSS.is_title);
    }

    const [totalClassName, setTotalClassName] = useState<string>(l_total_class_name_list.join(" "));

    useEffect(() => {
        const m_total_class_name_list: string[] = [];

        if(onClick){
            m_total_class_name_list.push("g_click_event_item");
        }

        if(class_name_list){
            for(const class_name of class_name_list){
                m_total_class_name_list.push(class_name);
            }
        }

        m_total_class_name_list.push(CSS.l_text);

        if(weight){
            m_total_class_name_list.push(CSS.weight);
        }

        if(size){
            m_total_class_name_list.push(CSS[size]);
        }

        if(color){
            m_total_class_name_list.push(CSS.color);
            m_total_class_name_list.push(CSS[color]);
        }

        if(bold){
            m_total_class_name_list.push(CSS.bold);
        }

        if(style){
            m_total_class_name_list.push(CSS.style);
        }

        if(is_container_highlight){
            m_total_class_name_list.push(CSS.is_container_highlight);
        }

        if(text_wrap){
            m_total_class_name_list.push(CSS.text_wrap);
            m_total_class_name_list.push(CSS[text_wrap]);
        }

        if(is_title){
            m_total_class_name_list.push(CSS.is_title);
        }

        setTotalClassName(m_total_class_name_list.join(" "));
    }, [weight, size, color, style, class_name_list, onClick, is_container_highlight, text_wrap, is_title])

    return(
        <div 
            className={totalClassName}
            onClick={onClick}
        >
            {children}
        </div>
    )
}

export default Text;