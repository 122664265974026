import { useEffect, useRef, useState } from "react";
import TextEditerQuill from "../../Component/Elements/TextEditer/TextEditerQuill";
import { customToast, isAbleAccesser } from "../../Common/ts/commonTools";
import { themaBookDetailListInterface } from "../../PublicPage/ThemeBook/static/interface/themeBook.interface";
import { useLocation, useNavigate } from "react-router-dom";
import { getDifferentImageUrls } from "../../Component/Elements/TextEditer/static/ts/tools";
import CSS from "./static/css/themeBookManagement.module.css";
import TitleContainer from "../../PublicPage/ThemeBook/components/TitleContainer";
import ThemeDetailModalBtnContainer from "../../PublicPage/ThemeBook/components/ThemeDetailModalBtnContainer";
import ThumbnailImageSelect from "./components/ThumbnailImageSelect/ThumbnailImageSelect";
import CardContainer from "../../Component/Elements/CardContainer/CardContainer";

const ThemeBookManagement = () => {
    
    const [mainContent, setMainContent] = useState<string>("");

    const [uploadedThumbnailSrc, setUploadedThumbnailSrc] = useState<string>("");
    const uploadedThumbnailFileRef = useRef<File | null>(null);
    
    const navigate = useNavigate();
    const location = useLocation();
    const selected_sub_theme = location.state as themaBookDetailListInterface;

    // 삭제되야할 이미지 목록
    const removedImageUrlListRef = useRef<string[]>([]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]; // 업로드한 파일 가져오기
        if (file && file.type.startsWith("image/")) {
            const imageUrl = URL.createObjectURL(file); // 이미지 URL 생성
            setUploadedThumbnailSrc(imageUrl); // 상태 업데이트
            uploadedThumbnailFileRef.current = file;
        } else {
            customToast.error({msg: "이미지 파일만 업로드 가능합니다."});
        }
    }
    
    useEffect(() => {
        isAbleAccesser(["E"]);

        if(!!selected_sub_theme){
            setMainContent(selected_sub_theme.content || "");
            removedImageUrlListRef.current = getDifferentImageUrls({
                origin: selected_sub_theme.content || "",
                newContent: mainContent,
                conditions: "removed",
            });
        }else{
            navigate("/themeBook");
        }
    }, [])

    const input_ref = useRef<HTMLInputElement | null>(null);

    return (
        <div className={CSS.l_theme_book_management_main}>
            <CardContainer 
                class_name_list={[CSS.l_theme_book_management__main_container]}
            >
                <div className={CSS.l_title_container}>
                    <TitleContainer 
                        photo_name={selected_sub_theme.photo_name || ""}
                        title={selected_sub_theme.thema_name || ""}
                        branch_name={selected_sub_theme.office_name || ""}
                    />
                </div>
                <div className={CSS.l_text_editer_quill_container}>
                    <TextEditerQuill
                        read_only={false}
                        content={mainContent}
                        setContent={setMainContent}
                        office_id={selected_sub_theme.office_id}
                        thema_id={selected_sub_theme.thema_id}
                        type={selected_sub_theme.type}
                    />
                </div>
                <div className={CSS.l_btn_container}>

                    {/* 모든 저장 동작은 이 컴포넌트 안에서 이루어진다. */}
                    <ThemeDetailModalBtnContainer 
                        themeData={selected_sub_theme}
                        isInEditPage={true}
                        removedImageUrlListRef={removedImageUrlListRef}
                        mainContent={mainContent}
                        uploadedThumbnailFile={uploadedThumbnailFileRef.current}
                    />
                </div>
            </CardContainer>

            <ThumbnailImageSelect 
                origin_src={selected_sub_theme.thema_thumbnail.middle_url}
                input_ref={input_ref}
                uploadedThumbnailSrc={uploadedThumbnailSrc}
                setUploadedThumbnailSrc={setUploadedThumbnailSrc}
                uploadedThumbnailFileRef={uploadedThumbnailFileRef}
            />

            <input 
                hidden 
                ref={input_ref} 
                accept="image/*"
                type="file"
                onChange={handleFileChange}
            />
        </div>
    )
}

export default ThemeBookManagement;