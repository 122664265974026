import Button from "../../../../Component/Elements/Button/Button";
import CardContainer from "../../../../Component/Elements/CardContainer/CardContainer";
import Modal from "../../../../Component/Elements/Modal/Modal";
import AddressContainer from "../AddressContainer/AddressContainer";
import useDaumPostcode from "../../../../Component/Elements/Postcode/hooks/useDaumPostcode.hook";
import { addressDataInterface } from "../../../../Component/Elements/Postcode/interface/useDaumPostcode.interface";
import { useEffect, useState } from "react";
import { callAxios, customToast, dev_console, isSuccess, printStateMsg } from "../../../../Common/ts/commonTools";
import { defaultApiReaponseInterface } from "../../../../Common/interface/Common.interface";
import CSS from "./static/css/AddressUpdateModal.module.css";
import usePhotoProductDataREST from "../../hooks/usePhotoProductDataREST.hook";

const AddressUpdateModal = (
    {
        album_save_id,
        isModalOpen,
        modalCloseHandler,
        initialCustomAddressData,
        initialDetailAddress,
    }:{
        album_save_id: number | null;
        isModalOpen: boolean;
        modalCloseHandler: () => void;
        initialCustomAddressData: addressDataInterface;
        initialDetailAddress: string;
    }
) => {
    const [isLoading ,setIsLoading] = useState<boolean>(false);

    // 전체 - 카테고리의 데이터들 refetch함수
    const {
        photoProductDataListRefetch: refetch0,
    } = usePhotoProductDataREST({
        search_type: 0
    });

    // 신청완료 - 카테고리의 데이터들 refetch함수
    const {
        photoProductDataListRefetch: refetch2
    } = usePhotoProductDataREST({
        search_type: 2
    });

    // 주소
    const {
        customAddressData,
        setCustomAddressData,
        setRowAddressData,
        detailAddress,
        setDetailAddress, 
    } = useDaumPostcode(null);

    const requestSetAddress = () => {
        setIsLoading(true);

        const config = {
            album_save_id: album_save_id || 0,
            addr: customAddressData.mainAddress,
            addr_detail: detailAddress,
            building_no: customAddressData.buildingCode,
            zip_code: customAddressData.zonecode,
        }

        const photo_select_add_update_url = "api/v3/customers/photo-select-add-update";

        dev_console.log(config);

        callAxios.api({
            url: photo_select_add_update_url,
            method: "post",
            data: config,
            dataType: "json",
        })
        .then(async (response) => {
            dev_console.log(photo_select_add_update_url);
            dev_console.log(response);

            if(isSuccess(response)){
                const data: defaultApiReaponseInterface<null> = response.data;

                if(data.status.code === 200){

                    // "/productionList"페이지 에서의 데이터들을 최신의것으로 업데이트한다. 
                    refetch0();
                    refetch2();
                    
                    customToast.success({msg: "주소 변경이 완료되었습니다."});
                    modalCloseHandler();
                }else if(data.status.code === 201){
                    printStateMsg(response);
                    customToast.error({msg: "제품 제작이 시작되어 주소변경이 불가능합니다."});
                }else{
                    printStateMsg(response);
                    customToast.error({msg: "주소 변경에 실패했습니다."});
                }
            }else{
                printStateMsg(response);
                customToast.error({msg: "주소 변경에 실패했습니다."});
            }
        })
        .catch((error) => {
            dev_console.error(error);
            customToast.error({msg: "주소변경 요청과정에서 문제가 발생했습니다."});
        })
        .finally(() => {
            setIsLoading(false);
        })
    }

    const requestHandler = () => {
        if(isLoading){
            customToast.info({msg: "주소 변경이 진행중입니다. 잠시 기다려주세요.", toastId: `AddressUpdateModal ${album_save_id}`})
        }

        if(!window.confirm("주소를 변경하시겠습니까?")){
            return ;
        }

        requestSetAddress();
    }

    useEffect(() => {
        setCustomAddressData(initialCustomAddressData);
    }, [initialCustomAddressData])

    useEffect(() => {
        setDetailAddress(initialDetailAddress);
    }, [initialDetailAddress])

    return(
        <Modal
            isModalOpen={isModalOpen}
            modalCloseHandler={modalCloseHandler}
        >
            <CardContainer class_name_list={[CSS.l_address_update_modal_main]}>
                <AddressContainer
                    customAddressData={customAddressData}
                    setRowAddressData={setRowAddressData}
                    detailAddress={detailAddress} 
                    setDetailAddress={setDetailAddress}
                />

                <Button
                    onClick={requestHandler}
                    purpose={isLoading ? "disabled" : "save"}
                    size={"auto"}
                >
                    배송지 저장
                </Button>
            </CardContainer>
        </Modal>
    )
}

export default AddressUpdateModal;