import { progressScheduleInterface } from "../../static/interface/reservationHome.interface";
import CSS from "./static/css/scheduleBlock.module.css";
import { useEffect, useState } from "react";
import Button from "../../../../Component/Elements/Button/Button";
import TextWrap from "../TextWrap/TextWrap";
import Dday from "../element/Dday";
import Text from "../../../../Component/Elements/Text/Text";
import { useNavigate } from "react-router-dom";
import { setProgressScheduleBtn } from "../../static/ts/reservation.tool";
import { getTimeDifferenceInMinutes } from "../../../../Common/ts/commonTools";
import InfoDataTextWrap from "../../../../Component/Elements/InfoDataTextWrap/InfoDataTextWrap";
import Textarea from "../../../../Component/Elements/Textarea/Textarea";
import TextareaWrap from "../TextareaWrap/TextareaWrap";
import Modal from "../../../../Component/Elements/Modal/Modal";

const ScheduleBlock = (
    {
        schedule
    }:{
        schedule: progressScheduleInterface
    }
) => {
    const [memoDetailPageModal, setMemoDetailPageModal] = useState<boolean>(false);

    const navigate = useNavigate();

    const result = setProgressScheduleBtn({item: schedule, navigate})
    const d_day_number: number = parseInt(schedule.d_day_num);
    
    const photo_time_list: string[] = [];
    if(schedule.detail){
        photo_time_list.push(schedule.detail.photo_start);
        photo_time_list.push("-");
        photo_time_list.push(schedule.detail.photo_end);
        
        const photo_lenth = getTimeDifferenceInMinutes(`${schedule.detail.photo_start}`, `${schedule.detail.photo_end}`, true);
        if(photo_lenth > -1){
            photo_time_list.push(`(${photo_lenth} 분)`);
        }
    }

    const memoDetailPageModalOpenHandler = () => {
        setMemoDetailPageModal(true);
    }

    const memoDetailPageModalCloseHandler = () => {
        setMemoDetailPageModal(false);
    }

    let preparation_item: React.ReactNode = <div></div>;
    if(schedule.detail && schedule.detail.memo.length > 0){
        preparation_item = (
            <div 
                className={CSS.l_text_box}
                onClick={memoDetailPageModalOpenHandler}
            >
                <TextareaWrap
                    label={"전달사항"}
                    text={schedule.detail.memo}
                />
            </div>
        )
    }

    return (
        <div 
            className={`g_card g_click_event_item ${CSS.l_card_item}`}
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            <Text
                class_name_list={[CSS.l_info_text_wrap]}
            >
                <div className={CSS.l_text_box}>
                    <Dday
                        d_day={d_day_number}
                    />
                </div>
                <div className={CSS.l_text_box}>
                    <TextWrap 
                        label="촬영 명칭"
                        text={schedule.detail?.schedule_title || ""}
                    />
                </div>
                <div className={CSS.l_text_box}>
                    <TextWrap
                        label="촬영 일자" 
                        text={schedule.schedule_date_format}
                    />
                </div>
                <div className={CSS.l_text_box}>
                    <TextWrap 
                        label="촬영 시간" 
                        text={photo_time_list.join(" ")}
                    />
                </div>
                <div className={CSS.l_text_box}>
                    <TextWrap 
                        label="계약 번호" 
                        text={schedule.detail?.contract_id || ""}
                    />
                </div>
                <div className={CSS.l_text_box}>
                    <TextWrap 
                        label="선택 테마"
                        text={schedule.detail?.thema_list_name.join(", ") || ""}
                    />
                </div>
                <div className={CSS.l_text_box}>
                    <TextWrap 
                        label="상태 상태"
                        text={schedule.status_text || ""}
                    />
                </div>
                <div className={CSS.l_text_box}>
                    {preparation_item}
                </div>
                <Button
                    size={"size2"}
                    onClick={result.onClick_btn}
                    purpose={result.purpose}
                >
                    {result.btn_title}
                </Button>
            </Text>

            <Modal
                isModalOpen={memoDetailPageModal}
                modalCloseHandler={memoDetailPageModalCloseHandler}
                isBackgroundClose={true}
            >
                <TextareaWrap
                    label={""}
                    text={schedule.detail?.memo || ""}
                />
            </Modal>
        </div>
    )
}

export default ScheduleBlock;