import { useEffect, useRef, useState } from "react";
import CSS from "./static/css/tooltip.module.css";
import tooltip_icon from "./static/img/g_tooltip.svg";

const Tooltip = (
    {
        children,
        tooltip_node,
        class_name_list,
        position,
        max_width,
        isUseIcon,
        onClick,
    }:{
        children?: React.ReactNode;
        tooltip_node?: React.ReactNode;
        class_name_list?: string[];
        position?: "center";
        max_width?: "small" | "middle" | "big" | "auto";
        isUseIcon?: boolean;
        onClick?: React.MouseEventHandler<HTMLDivElement>
    }
) => {
    const [tooptipContainerTotalClassName, setTooptipContainerTotalClassName] = useState<string>("");
    const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
    const [tooltipPositionStyle, setTooltipPositionStyle] = useState<React.CSSProperties>({});
    const tooltipRef = useRef<HTMLDivElement | null>(null);

    const handleMouseEnter = () => {
        setIsTooltipVisible(true);
    };
    
    const handleMouseLeave = () => {
        setIsTooltipVisible(false);
    };

    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if(position){
            setTooltipPositionStyle({
                top: "50%", 
                right: "10%",
                transform: "translate(0, -50%)",
            })
        }else if(tooltipRef.current){
            const tooltip_width = tooltipRef.current.offsetWidth || 0;
            const tooltip_height = tooltipRef.current.offsetHeight || 0;
    
            let new_x = event.clientX;
            let new_y = event.clientY;
    
            // 화면 오른쪽으로 나가는 경우
            if (new_x + tooltip_width > window.innerWidth) {
                new_x = event.clientX - tooltip_width;
            }
    
            // 화면 아래쪽으로 나가는 경우
            if (new_y + tooltip_height > window.innerHeight) {
                new_y = event.clientY - tooltip_height - 10;
            }

            setTooltipPositionStyle({
                top: new_y, 
                left: new_x
            })
        }
    }

    useEffect(() => {
        const total_class_name_list: string[] = [];

        if(class_name_list){
            for(const class_name of class_name_list){
                total_class_name_list.push(class_name);
            }
        }

        total_class_name_list.push(CSS.l_tooptip_container);

        if(max_width){
            total_class_name_list.push(CSS.max_width);
            total_class_name_list.push(CSS[max_width]);
        }

        setTooptipContainerTotalClassName(total_class_name_list.join(" "));
    }, [class_name_list, max_width])

    return (
        <div 
            className={CSS.l_tooltip_main}
            onMouseEnter={handleMouseEnter} 
            onMouseLeave={handleMouseLeave} 
            onMouseMove={handleMouseMove}
            onClick={onClick}
        >
            {
                isTooltipVisible && tooltip_node
                &&  <div 
                        ref={tooltipRef}
                        className={tooptipContainerTotalClassName}
                        style={tooltipPositionStyle}
                    >
                        {tooltip_node}
                    </div>  
            }
            {children}
            {
                isUseIcon
                &&  <div className={CSS.tooltip_icon_container}>
                        <img
                            className={CSS.tooltip_icon}
                            src={tooltip_icon}
                        />
                    </div>
            }
        </div>
    )
}

export default Tooltip;