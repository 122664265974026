import Text from "../../../../Component/Elements/Text/Text";
import CSS from "./static/css/FolderItemCard.module.css";
import { forwardRef, useEffect, useState } from "react";
import ImageViewer from "../../../../Component/Elements/ImageViewer/ImageViewer";

interface folderItemCardInterface{
    folder_name: string;
    isSatisfiedPhotoNums: boolean;
    scrollToFirstFalseItemHandler: React.MouseEventHandler<HTMLDivElement>;
    img_list_length: number;
    img_src: string;
    thumbnail_src: string;
    is_open_thumbnail: boolean;
}

const FolderItemCard = forwardRef<HTMLDivElement, folderItemCardInterface>((
    {
        folder_name,
        isSatisfiedPhotoNums,
        scrollToFirstFalseItemHandler,
        img_list_length,
        img_src,
        thumbnail_src,
        is_open_thumbnail,
    }, ref
) => {
    const [isActivate, setIsActivate] = useState<boolean>(false);

    useEffect(() => {
        setIsActivate(is_open_thumbnail);
    }, [is_open_thumbnail])

    return(
        <div
            className={
                isActivate
                ?   `${CSS.l_folder_item_card_main} ${CSS.open}`
                :   `${CSS.l_folder_item_card_main}`
            }
            onClick={scrollToFirstFalseItemHandler}
            ref={ref}
        >
            <Text
                class_name_list={[CSS.l_folder_item_card__folder_name]}
                size={"size1"}
                color={isSatisfiedPhotoNums ? "green" : "dark"}
                bold={true}
                text_wrap={"pretty"}
            >
                {folder_name} ({img_list_length})
            </Text>

            {
                !!img_src && !!thumbnail_src
                &&  <div 
                        className={
                            isActivate
                            ?   `g_click_event_item ${CSS.l_folder_item_card__thumbnail_container} ${CSS.open}`
                            :   `g_click_event_item ${CSS.l_folder_item_card__thumbnail_container}`
                        }
                    >
                        <ImageViewer 
                            img_src={img_src}
                            thumbnail_src={thumbnail_src}
                            is_view_toolbar={false}
                            // thumbnail_hidden={true}
                        />
                    </div>
            }
        </div>
    )
})

export default FolderItemCard;