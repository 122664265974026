import { useEffect, useLayoutEffect, useRef, useState } from "react";
import FolderItemCard from "../../../CustomerPage/ProductionList/components/FolderItemCard/FolderItemCard";
import ImgDargAndDropCard from "../../../CustomerPage/ProductionList/components/ImgDargAndDropCard/ImgDargAndDropCard";
import { scheduleProductFolderInterface } from "../../../CustomerPage/ProductionList/static/interface/ProductionList.interface";
import CSS from "./static/css/FolderImageViewer.module.css";
import { viewerImageInterface } from "../ImageGallery/interface/ImageGallery.interface";
import { getFileType } from "../../../Common/ts/commonTools";

const FolderImageViewer = (
    {
        folderList,
        imgFiles, 
        setImgFiles,
        isSatisfiedPhotoNums,
        setIsSatisfiedPhotoNums,
        isUseMultiFolder,
        isReadOnly,
        cardRefs,
    }:{
        folderList: scheduleProductFolderInterface[];
        imgFiles?: {[key: number]: File[];};
        setImgFiles?: React.Dispatch<React.SetStateAction<{[key: number]: File[];}>>;
        isSatisfiedPhotoNums?: {[key:number]: boolean;};
        setIsSatisfiedPhotoNums?: React.Dispatch<React.SetStateAction<{[key:number]: boolean;}>>;
        isUseMultiFolder: boolean;
        isReadOnly?: boolean;
        cardRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
    }
) => {
    const folderRefs = useRef<Array<HTMLDivElement | null>>([]);

    // 폴더리스트에 할당해주는 ref => 폴더리스트의 스크롤 동작을 컨트롤하는데 사용된다.
    const [isActivatedFolderId, setIsActivatedFolderId] = useState<number | null>(null);

    const viewer_container_ref = useRef<HTMLDivElement | null>(null);

    // 스크롤 액션 함수
    const scrollToFirstFalseItemHandler = (folder_id: number) => {               
        if (!!cardRefs.current[folder_id]) {
            // 스크롤 이동
            cardRefs.current[folder_id]?.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };  

    useLayoutEffect(() => {
        const onScroll = () => {
            if (viewer_container_ref.current) {
                const containerTop = viewer_container_ref.current.getBoundingClientRect().top;
                const items = Array.from(viewer_container_ref.current.querySelectorAll('.folder_list_item')) as HTMLElement[];
                
                const closestItem = items.reduce<HTMLElement | null>((closest, item) => {
                    const itemTop = item.getBoundingClientRect().top - containerTop; // 컨테이너 최상단을 기준으로 한 상대적 top 값
                    return (closest === null || Math.abs(itemTop) < Math.abs(closest.getBoundingClientRect().top - containerTop))
                        ? item 
                        : closest;
                }, null);
    
                if (closestItem) {
                    const folderId = Number(closestItem.getAttribute('data-folder-id'));
                    setIsActivatedFolderId(folderId);
                }
            }
        };
    
        viewer_container_ref.current?.addEventListener('scroll', onScroll);
        return () => viewer_container_ref.current?.removeEventListener('scroll', onScroll);
    }, []);

    useLayoutEffect(() => {

        if(!!folderList && folderList.length > 0){
            setIsActivatedFolderId(folderList[0].folder_id);
        }

        const viewerContainer = viewer_container_ref.current;
    
        if (viewerContainer && viewerContainer.children.length > 1) {
            const lastItem = viewerContainer.lastElementChild as HTMLElement;
    
            const calculateMarginBottom = () => {
                const containerOffsetHeight = viewerContainer.offsetHeight;
                const lastItemOffsetHeight = lastItem.offsetHeight;
                const interval = containerOffsetHeight - lastItemOffsetHeight;
    
                if (interval > 0) {
                    lastItem.style.marginBottom = `${interval}px`;
                } else {
                    lastItem.style.marginBottom = `0px`;
                }
            };
    
            // 초기 계산 수행
            calculateMarginBottom();
    
            // MutationObserver를 설정하여 마지막 항목 높이 변화 감지
            const observer = new MutationObserver(calculateMarginBottom);
            observer.observe(lastItem, { attributes: true, childList: true, subtree: true });
    
            // cleanup
            return () => observer.disconnect();
        }
    }, [folderList]);

    // // 폴더목록에서 지금 보고있는 폴더로 스크롤을 이동시킴
    // useEffect(() => {
    //     const index = folderList.findIndex((item) => item.folder_id === isActivatedFolderId);
    
    //     if (index !== -1 && folderRefs.current[index]) {
    //         folderRefs.current[index]?.scrollIntoView({
    //             behavior: "smooth", // 스크롤 이동 시 애니메이션 효과
    //             block: "center", // 아이템이 화면 중앙에 위치하도록 조정
    //         });
    //     }
    // }, [isActivatedFolderId]);

    // 폴더목록에서 지금 보고있는 폴더로 스크롤을 이동시킴 -> 오버플로우의 크기변화를 감지하는 기능이 추가된버전
    useEffect(() => {
        const index = folderList.findIndex((item) => item.folder_id === isActivatedFolderId);
    
        if (index !== -1 && folderRefs.current[index]) {
            const targetElement = folderRefs.current[index];
        
            // MutationObserver로 크기 변화를 감지
            const observer = new MutationObserver(() => {
                if(!!targetElement){
                    targetElement.scrollIntoView({
                        behavior: "smooth",
                        block: index === folderList.length - 1 ? "end" : "nearest", // 마지막 항목은 하단까지 보이게 설정
                    });
                }
            });
        
            if(!!targetElement){                
                observer.observe(targetElement, {
                    attributes: true,
                    childList: true,
                    subtree: true,
                });
            }
        
            // 스크롤 조정 후 Observer 해제
            return () => observer.disconnect();
        }
    }, [isActivatedFolderId, folderList.length]);

    return(
        <div className={CSS.l_folder_image_viewer_main}>
            {
                isUseMultiFolder
                &&  <div className={`g_display_none_object mobile flex ${CSS.l_uploaded_image_wrap_modal__main_container__folder_list_container}`}>
                        {
                            folderList.map((item, index) => {
                                return(
                                    <FolderItemCard
                                        key={index}
                                        folder_name={item.folder_name}
                                        isSatisfiedPhotoNums={!!isSatisfiedPhotoNums && isSatisfiedPhotoNums[item.folder_id]}
                                        scrollToFirstFalseItemHandler={scrollToFirstFalseItemHandler.bind(null, item.folder_id)}
                                        img_list_length={
                                            isReadOnly
                                            ?   item.select_photo_list.length || 0
                                            :   imgFiles && imgFiles[item.folder_id]?.length || 0
                                        }
                                        img_src={item.folder_img_url.org_url}
                                        thumbnail_src={item.folder_img_url.small_url}
                                        is_open_thumbnail={isActivatedFolderId === item.folder_id}
                                        ref={(el) => (folderRefs.current[index] = el)} // 각 FolderItemCard에 ref 할당
                                    />
                                )
                            })
                        }
                    </div>
            }

            <div className={CSS.l_uploaded_image_wrap_modal__drag_and_drop_container}>
                <div 
                    className={CSS.l_uploaded_image_wrap_modal__drag_and_drop_container__cover}
                    ref={viewer_container_ref}
                >
                    {
                        folderList.length > 0
                        &&  folderList.map((item, index) => {
                                return(
                                    <div 
                                        key={index}
                                        ref={(el) => cardRefs.current[item.folder_id] = el}
                                        className={`folder_list_item ${CSS.l_uploaded_image_wrap_modal__drag_and_drop_container__cover__item_cover}`}
                                        data-folder-id={item.folder_id} //  
                                    >
                                        <ImgDargAndDropCard
                                            imgFiles={imgFiles}
                                            setImgFiles={setImgFiles}
                                            folder={item}
                                            needPhotoNum={{
                                                min: item.min_photo_count,
                                                max: item.max_photo_count,
                                            }}
                                            isSatisfiedPhotoNums={isSatisfiedPhotoNums}
                                            setIsSatisfiedPhotoNums={setIsSatisfiedPhotoNums}
                                            disabled={isReadOnly}
                                            initialImgList={item.select_photo_list.map((image_item) => {
                                                    const file_type = getFileType(image_item.img_url_org)

                                                    const return_value: viewerImageInterface = {
                                                        thumbnail_url: image_item.img_url.small_url,
                                                        origin_url: image_item.img_url.middle_url,
                                                        file_type: file_type,
                                                        file_name: image_item.file_name,
                                                        folder_id: item.folder_id
                                                    }

                                                return return_value;
                                            })}
                                            isUseMultiFolder={isUseMultiFolder}
                                        />
                                    </div>
                                )
                            })
                    }
                </div>
            </div>
        </div>
    )
}

export default FolderImageViewer;