import { useEffect } from "react";
import CSS from "./static/css/modal.module.css";
import Text from "../Text/Text";

const Modal = (
    {
        isModalOpen,
        modalCloseHandler,
        className,
        children,
        isBackgroundClose,
    }:{
        isModalOpen: boolean;
        modalCloseHandler?: () => void;
        className?: string;
        children?: React.ReactNode;
        isBackgroundClose?: boolean;
    }
) => {
    const backBtnHandler = () => {
        if(modalCloseHandler){
            modalCloseHandler();
        }
    };

    // 모달이 열려있을 때 뒤로가기버튼을 통해 모달이 닫힐 수 있도록 처리
    useEffect(() => {

        // 로딩창 출력의 목적으로 모달이 출력된 경우에도 해당 기능은 예외없이 동작하므로, 뒤로가기버튼 자체가 막힐것이다.(로딩창에선 modalCloseHandler 자체가 없기때문에)
        // 로딩창은 벗어나지 않는것이 좋다고판단되므로 유지한다.
        if(isModalOpen){
            window.history.pushState(null, "", window.location.href);
            window.addEventListener("popstate", backBtnHandler);
        }else{
            window.removeEventListener('popstate', backBtnHandler);
        }

        return(() => {
            window.removeEventListener('popstate', backBtnHandler);
        })
    }, [isModalOpen])

    if(isModalOpen){
        return (
            <div 
                className={
                    className
                    ? `g_no_print ${className} ${CSS.l_modal}`
                    : `g_no_print ${CSS.l_modal}`   
                }
                onClick={isBackgroundClose ? modalCloseHandler : undefined}
            >
                <div className={CSS.l_modal__children_container}>
                    {
                        !!modalCloseHandler
                        &&  <div 
                                className={`g_icon g_click_event_item g_no_print ${CSS.l_modal__modal_close_btn}`}
                                onClick={modalCloseHandler}
                            >
                                <Text  
                                    class_name_list={[CSS.l_close_btn]}
                                >
                                    X
                                </Text>
                            </div>
                    }

                    <div 
                        className={CSS.l_modal__children_container__children}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        {children}
                    </div>
                </div>
            </div>
        )
    }else{
        return null;
    }
}

export default Modal