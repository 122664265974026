import { useEffect, useState } from "react";
import CSS from "./static/css/infoDataTextWrap.module.css";
import Text from "../Text/Text";
import Textarea from "../Textarea/Textarea";

export interface infoDataElementInterface{
    title?: string,
    value: string,
    is_bold?: boolean;
    is_textarea?: boolean;
}

// 컴포넌트 내부에서 사용되는 전용 컴포넌트
const InfoDataTextWrap = (
    {
        label_text,
        value_text,
        diraction,
        justify_content,
        is_bold,
        is_textarea,
    }:{
        label_text?: string;
        value_text: string;
        diraction?: "row" | "column"
        justify_content?: "flex_start" | "flex_end" | "center" | "space_around" | "space_between" | "space_evenly",
        is_bold?: boolean;
        is_textarea?: boolean;
    }
) => {

    const [totalClassName, setTotalClassName] = useState<string>(CSS.l_info_data_text_wrap_main);

    useEffect(() => {
        
        const class_name_list: string[] = [];

        if(diraction && diraction === "column"){
            class_name_list.push("diraction_column");
        }

        class_name_list.push(CSS.l_info_data_text_wrap_main);

        if(justify_content){
            class_name_list.push(CSS.justify_content);
            class_name_list.push(CSS[justify_content]);
        }

        setTotalClassName(class_name_list.join(" "))

    }, [diraction, justify_content])

    return (
        <div className={totalClassName}>
            {
                label_text
                &&  <Text
                        // size={"size6"}
                        // bold={true}
                        class_name_list={[CSS.l_label_text]}
                        text_wrap={"pretty"}
                    >
                        {label_text}
                    </Text>
            }

            {
                is_textarea
                ?   <Textarea
                        value={value_text}
                        class_name_list={[CSS.l_value_textarea]}
                        readOnly
                    />
                :   <Text
                        color={"dark"}
                        bold={true}
                        class_name_list={[CSS.l_value_text]}
                        is_container_highlight={is_bold}
                        text_wrap={"pretty"}
                    >
                        {value_text}
                    </Text>
            }
        </div>
    )
}

export default InfoDataTextWrap;