import Text from "../../../../Component/Elements/Text/Text";
import Textarea from "../../../../Component/Elements/Textarea/Textarea";
import CSS from "./static/css/TextareaWrap.module.css";

const TextareaWrap = (
    {
        label,
        text,
    }:{
        label?: string;
        text: string;
    }
) => {
    return(
        <div className={CSS.l_TextareaWrap_main}>
            {
                label
                &&  <Text
                        size={"size1"}
                        bold={true}
                        class_name_list={[CSS.l_TextareaWrap__label]}
                    >
                        {label}
                    </Text>
            }
            <Textarea
                value={text}
                class_name_list={[CSS.l_TextareaWrap__Textarea]}
                readOnly={true}
            />
        </div>
    )
}

export default TextareaWrap;