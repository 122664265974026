import { forwardRef, useEffect, useState } from "react";
import CSS from "./static/css/VideoPlayer.module.css";
import Text from "../Text/Text";

interface videoPlayerInterface{
    src?: string;
    video_controls?: boolean;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    class_name_list?: string[];
}

const VideoPlayer = forwardRef<HTMLVideoElement | HTMLDivElement | HTMLImageElement, videoPlayerInterface>((
    {
        src,
        video_controls = true,
        onClick,
        class_name_list,
    }, ref
) => {
    const classNameSetter = () => {
        const m_class_name_list: string[] = [];

        if(class_name_list){
            for(const class_name of class_name_list){
                m_class_name_list.push(class_name);
            }
        }

        m_class_name_list.push(CSS.l_video_player_main);

        return m_class_name_list;
    }

    const [totalClassName, setTotalClassName] = useState<string>(classNameSetter().join(" "));

    const [videoError, setVideoError] = useState(false); // 비디오 로드 오류 상태

    const handleVideoError = () => {
        setVideoError(true); // 비디오 로드 오류 시 상태 업데이트
    };

    useEffect(() => {
        setTotalClassName(classNameSetter().join(" "));
    }, [class_name_list])

    return(
        <div 
            className={totalClassName}
            onClick={onClick}
        >
            {
                videoError && video_controls === false
                ?   <div ref={ref as React.Ref<HTMLDivElement>}>
                        <Text>
                            동영상
                        </Text>
                    </div>
                :   <video 
                        className={CSS.l_video_player__video}
                        controls={video_controls}
                        onError={handleVideoError}
                        ref={ref as React.Ref<HTMLVideoElement>}
                    >
                        <source src={src} type="video/mp4"/>
                    </video>
            }
        </div>
    )
})

export default VideoPlayer;