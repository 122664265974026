import CSS from "../../static/css/infoBlock.module.css";
import camera_icon from "../../static/img/Group 17518.svg";
import shopping_basket_icon from "../../static/img/Group 17519.svg"
import { useEffect, useState } from "react";
import Text from "../../../../../Component/Elements/Text/Text";

const InfoBlock = (
    {
        mode,
        data,
        use_tooltip,
    }:{
        mode: "date" | "necessary_item";
        data: string;
        use_tooltip?: boolean;
    }
) => {

    const [node, setNode] = useState<React.ReactNode | null>(null);

    let icon: string = "";
    let title: string = "";

    if(mode === "date"){
        icon = camera_icon;
        title = "촬영일시";
    }else if(mode === "necessary_item"){
        icon = shopping_basket_icon;
        title = "전달사항";
    }

    useEffect(() => {
        if(data){
            let state = 0;
            if(use_tooltip){
                state = 1;
            }else{
                state = 2;
            }

            switch(state){
                case 1:
                    setNode(
                        <Text
                            size={"size3"}
                            color={"dark"}
                            class_name_list={[CSS.l_info_block__info__data__icon_text]}
                        >
                            {data}
                        </Text>
                    );
                    break;
                case 2:
                    setNode(                
                        <Text
                            size={"size3"}
                            color={"dark"}
                            class_name_list={[CSS.l_info_block__info__data__text]}
                        >
                            {data}
                        </Text>
                    );
                    break;
                default:
            }
        }else{
            setNode(null);
        }
    }, [data, mode, use_tooltip])

    if(!data){
        return <></>
    }

    return(
        <div className={CSS.l_info_block_main}>
            <div className={CSS.l_info_block__icon_container}>
                <img
                    className={CSS.l_info_block__icon__img}
                    src={icon}
                />
            </div>
            <div className={CSS.l_info_block__info_container}>
                <Text
                    size={"size1"}
                    class_name_list={[CSS.l_info_block__info__title]}
                >
                    {title}
                </Text>
                <div className={`${CSS.l_info_block__info__data}`}>
                    {node}
                </div>
            </div>
        </div>
    )
}

export default InfoBlock;